import { useEffect } from "react";

export const IkonWalletBtn = ({amount, ikonId}) => {

  useEffect(() => {
    // Load the external script
    const script = document.createElement("script");
    script.src = "https://ikondev.vercel.app/api/assets/js/order-button";
    script.async = true;
    document.body.appendChild(script);

    // Define the callback function
    const callback = (status, response) => {
      console.log(response, status);
    };

    // Assign callback to the global window object
    window.ikonOnOrderCallback = callback;
    // @ts-ignore

    // Ensure the function is called after the script loads
    script.onload = () => {
      // @ts-ignore
      if (typeof loadIkonBtnFns === "function") {
        // @ts-ignore
        loadIkonBtnFns(ikonId, "ikonOnOrderCallback", false);
      }
    };

    // Cleanup function to remove the script when unmounting
    return () => {
      document.body.removeChild(script);
      if (typeof window.ikonOnOrderCallback !== 'undefined') {
        // @ts-ignore
        delete window.ikonOnOrderCallback;
      }
    };
  }, []);

  return (
    <button id="ikon-order-now-button" type="button" className="btn btn-primary btn-lg">
      Pay &nbsp;<strong>${amount}</strong>
    </button>
  )
}