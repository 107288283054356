import { useEffect, useRef, useState } from "react";
// @ts-ignore
import code from '@code-wallet/elements';
import { authHeader } from "../../lib/utils";
import { NODE_API_URL } from "../../lib/config";
import { fetchCodeIntent } from "../../requests/message-req";
import { handleCodePayment } from "../../requests/session-req";
import { getUserCredit } from "../../requests/fetch";
import { useAppContext } from "../../context/app.context";
import { useAuthUser } from "react-auth-kit";
import useSocket from "../../hooks/useSocket";
import { TAppUser } from "../../lib/types";
import { useSessionStore } from "../../context/session.store";


export const CodeWalletBtn = ({amount, productId}) => {
  const user = useAuthUser()
  const header = authHeader()

  const authUser = user && user() as TAppUser
  const token = header?.replace('Bearer ', '')
  const webSocketClient = useSocket()
  
  const el = useRef<HTMLDivElement>(null);
  const { setUserCredits, showNotification } = useAppContext()
  const [intent, setIntent] = useState<string | null>(null)

  const setSelectedOption = useSessionStore(state => state.setSelectedPaymentOption)



  useEffect(() => {
    if (!intent) return

    // webSocketClient.users
    if (!authUser?.ID || !intent) return
    webSocketClient.logIn({ sessionId: intent, userId: authUser.ID })

  }, [authUser, intent, webSocketClient])
  
  useEffect(() => {
    
    const { button } = code.elements.create('button', {
      currency: 'usd',
      destination: 'DagRzeaZGrvk7euSpnmZ8TmWYNc3kJzUes91WnU1t9e',
      amount: amount || 1,
    });

    if (!button) return

    button.on('success', async (e) => {
      await handleCodePayment({intent: e.intent, productId});
      const { credits, earnings } = await getUserCredit()

      setUserCredits(credits, earnings);

      showNotification({
        message: 'Payment successful',
        title: 'Top-up successful',
        type: 'bg-success'
      })

      setSelectedOption(null)
      return true;
    });

    button.on('invoke', async () => {

      const { clientSecret, id } = await fetchCodeIntent({amount, messageId: ''})

      // Update the button with the new client secret generated by the server.
      button.update({ clientSecret });

      setIntent(id)
      // return true; // Return true to cancel the payment intent (if needed)
    });

    button.mount(el.current!);

  }, []);

  return (
    <div ref={el} />
  )
}