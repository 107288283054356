export const CustomLink = ({ href = '', children, ...props }) => {
  const imagePattern = /\.(png|jpe?g|gif|svg|webp)$/i;
  const videoPattern = /\.(mp4|webm)$/i; 
  const audioPattern = /\.(mp3|wav)$/i;

  if (audioPattern.test(href)) {
    return (
      <div style={{ width: '300px', display: 'block' }}>
        <p>
          {children}
        </p>
        <audio src={href} controls {...props} style={{ width: '100%' }}>
          {children}
        </audio>
      </div>
    );
  }

  if (imagePattern.test(href)) {
    return (
      <div style={{ width: '300px', display: 'block' }}>
        <p>
          {children}
        </p>
        <img src={href} alt={children[0] || 'Image'} {...props} style={{ width: '100%' }} />
      </div>
    );
  }

  if (videoPattern.test(href)) {
    return (
      <div style={{ width: '300px', display: 'block' }}>
        <p>
          {children}
        </p>
        <video src={href} controls {...props} style={{ width: '300px' }}>
          {children}
        </video>
      </div>
    );
  }

  return <a href={href} {...props}>{children}</a>;
};
