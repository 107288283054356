import { create } from 'zustand'
import { produce } from 'immer'
import { generate } from "random-words";
import { createSession, createTempSession, deleteUserSession, getSharedSessions, getUserSessions } from '../requests/graphql/session';
import { checkConnectionWithWp, connectWithWp } from '../requests/fetch';
import { TSession, TTopupOption } from '../lib/types';


type TAccessForm = {
  email: string;
  woo_ck_key_r?: string;
  woo_cs_key_r?: string;
  woo_ck_key_w?: string;
  woo_cs_key_w?: string;
  site_url: string;
  sessionId: string;
  storeName: string;
};

type  TStoreData = {
  userId: string;
  email: string;
  sessionId: string;
  site_url: string;
  store_logo: string;
  storeName: string;
}


type TStore = {
  isLoading: boolean
  sessionId?: string
  userSessions: TSession[], 
  sharedSessions: TSession[], 
  storeIsConnected: boolean
  store: TStoreData | null
  isSharing: string | null
  isCollapsed: boolean,
  userCredits: { credits: number, earnings: { kin: number } };
  selectedPaymentOption: TTopupOption | null;
  setSelectedPaymentOption: (option: TTopupOption | null) => void
  setUserCredits: (credits: number, earnings: { kin: number }) => void
  setQuickAction: (isShown: boolean) => void,
  showShare: (state: string | null) => void
  setSessionId: (sessionId: string) => void
  setLoading: (state: any) => void
  loadSessions: () => Promise<void>
  deleteSession: (sessionId: string) => Promise<void>
  createNewSession: (agentType?: string) => Promise<any>
  checkWooStoreConnection: (form: {sessionId: string}) => Promise<any>
  createWooStoreConnection: (form: TAccessForm, access: 'read' | 'write') => Promise<any> 
  createNewTempSession: (linkId: string, rootSessionId: string) => Promise<any>
}

export const useSessionStore = create<TStore>((set, get) => {
  return {
    session: null,
    store: null,
    storeIsConnected: false,
    isLoading: false,
    sessionId: undefined,
    userSessions: [],
    sharedSessions: [],
    isSharing: null,
    isCollapsed: true,
    userCredits: { credits: 0, earnings: { kin: 0 } },
    selectedPaymentOption: null,
    setSelectedPaymentOption(option) {
      set(produce(get(), draft => {
        draft.selectedPaymentOption = option
      }))
    },
    setUserCredits(credits, earnings) {
      set(produce(get(), draft => {
        draft.userCredits = { credits, earnings }
      }))
    },
    setQuickAction(isShown) {
      set(produce(get(), draft => {
        draft.isCollapsed = isShown
      }))      
    },
    showShare(state) {
      set(produce(get(), draft => {
        draft.isSharing = state
      }))
    },
    async deleteSession(sessionId) {
      get().setLoading(true)
      
      await deleteUserSession(sessionId)
      get().setLoading(false)
    },
    async loadSessions() {
      get().setLoading(true)
      
      const userSessions = await getUserSessions()
      const sharedSessions = await getSharedSessions()
      set(
        produce(get(), draft => {
          draft.userSessions = userSessions
        })
      )
      set(
        produce(get(), draft => {
          draft.sharedSessions = sharedSessions
        })
      )
      get().setLoading(false)
    },
    setSessionId: (sessionId: string) => {
      set(produce(get(), state => {
        state.sessionId = sessionId
      }))
    },
    setLoading: bool => {
      set(produce(get(), state => {
        state.isLoading = bool
      }))
    },
    createNewSession: async (agentType) => { 
      // call create session api
      // save session to store
      // generate a session name
      const words = generate(3) as string[]
      const sessionName = words.join("_") 
      get().setLoading(true)
      const response = await createSession(sessionName, agentType)

      if (response) {
        set(produce(get(), state => {
          state.sessionId = response.id
        }))
      }
      get().setLoading(false)
      return response
    },
    createNewTempSession: async (linkId, rootSessionId) => { 

      get().setLoading(true)
      const response = await createTempSession(linkId, rootSessionId)

      if (response) {
        set(produce(get(), state => {
          state.sessionId = response.id
        }))
        localStorage.setItem('sessionId', response.id);
      }
      get().setLoading(false)
      return response
    },
    checkWooStoreConnection: async (form) => {
      get().setLoading(true)
      const response = await checkConnectionWithWp(form)
      if (response) {
        set(produce(get(), state => {
          state.storeIsConnected = true
        }))
        set(produce(get(), state => {
          state.store = response
        }))
      }
      get().setLoading(false)
      return response
    },
    createWooStoreConnection: async (form, access) => {
      get().setLoading(true)
      const response = await connectWithWp(form, access)
      if (response) {
        set(produce(get(), state => {
          state.storeIsConnected = true
        }))
      }
      get().setLoading(false) 
      return response
    }
  }
})