/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect } from 'react'
import ThreadFooter from './ThreadFooter'
import { useThreadStore } from '../../context/thread.store'
import Message from './Message'
import useSocket from '../../hooks/useSocket'
import { ChatBottom } from './ChatBottom'
import { useSessionStore } from '../../context/session.store'
import { updateSessionProps } from '../../requests/session-req'
import { useAgentStore } from '../../context/agent.store'
import QuickTodo from './QuickTodo'
import { useAppContext } from '../../context/app.context'

function Thread({ isShared= false }) {
  // should use route params to get the thread id
  const isMd = window.innerWidth > 768
  const { userCredits } = useAppContext()


  const messages = useThreadStore(state => state.messages)
  const getMessages = useThreadStore(state => state.getSessionMessages)
  const setShowingAgents = useThreadStore(state => state.setShowingAgents)

  const isLoading = useThreadStore(state => state.isLoading)
  const isUploading = useThreadStore(state => state.isUploading)
  const isTyping = useThreadStore(state => state.isTyping);
  const isReplying = useThreadStore(state => state.isComposing);
  const sessionId = useSessionStore(state => state.sessionId)

  const resetSelectedAgents = useAgentStore(state => state.resetSelectedAgents)
  const webSocketClient = useSocket()


  useEffect(() => {
    if (!sessionId || sessionId === "") return
    updateSessionProps(sessionId!)
    return () => {
      setShowingAgents(false)
      resetSelectedAgents()
      webSocketClient.logOut()
    }

  }, [sessionId])

  


  const checkForNewMessages = useCallback(async (e) => {
    e.preventDefault();
    const lastMessage = messages[messages.length - 1  as number]
    await getMessages({
      after: lastMessage?.createdAt,
      sessionId: sessionId!,
    });
  }, [sessionId, messages])





  return (
    <div className="app-section">
      <section className="row my-5 pb-5">
        <>
          <div id="appCapsule">
            {
              messages.map((message, index) => {
                return <Message key={index} message={message} />
              })
            }
            <div className="message-item message-end"></div>

          </div>
          <ChatBottom isUploading={isUploading} isTyping={isTyping} isLoading={isLoading} checkForNewMessages={checkForNewMessages} />

          {
            isLoading && !isReplying && (
              <div className="app-progress-bar">
                <div className="progress-bar">
                  <div className="progress-bar-value"></div>
                </div>
              </div>
            )
          }
          {
            ( 
              ( Number(userCredits.credits) > 0 && !isReplying ) || 
              isShared
            ) && <ThreadFooter isShared={isShared} />
          }
      {
        (!isMd && !isShared ) && (
          <div className="thread-quick-todo">
            <QuickTodo />
          </div>
        )
      }
        </>
      </section>
    </div>
  )


}

export default Thread

