import { PaymentMethodId, TCheckoutPageProps } from "../../lib/types";


export const CheckoutPayment = (
  {
    topupOption,
    paymentMethods,
    onRemoveOption
  }: TCheckoutPageProps
) => {

  return (
    <>
      <div className="row" >
        {
          topupOption && (
            <div className="col-12 col-sm-6">
              <div className="card widget-card border-light">
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-8">
                      <h5 className="card-title widget-card-title mb-3 fw-bold text-secondary">{topupOption.name}</h5>
                      <h4 className="card-subtitle text-body-secondary m-0"></h4>
                    </div>
                    <div className="col-4">
                      <div className="d-flex justify-content-end">
                        <div className="lh-1 h1 text-black d-flex align-items-center justify-content-center" style={{fontSize: '28px'}}>
                          $ {topupOption.amount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex align-items-center mt-3 justify-content-between">
                        <div>
                          <p className="fs-7 mb-0 text-secondary">{topupOption.description}</p>
                        </div>
                        <div>
                          <p
                            onClick={onRemoveOption}
                            className="fs-7 mb-0 text-primary"
                          >
                            Remove
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className="row mt-5">
        <p className="fs-5">
          Payment Methods
        </p>
      </div>
      <div className="row gap-2 d-flex flex-column flex-md-row">
        {
          paymentMethods.map((method, index) => (
            <div className="col-md-3 col-sm-6" key={index}>
              <div className="card" style={{ width: "18rem" }}>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5 className="card-title">
                        {method.name}
                      </h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        {method.subtitle}
                      </h6>
                    </div>
                    <div>
                      <img src={method.icon} style={{ "width": "30px", height: "30px", objectFit: "contain" }} alt="" />
                    </div>
                  </div>
                  <p className="card-text">
                    {method.description}
                  </p>
                  {
                    topupOption && (
                      <>
                        {
                          method.isNA && (
                            <button disabled className="btn btn-primary btn-lg">
                              Not Available
                            </button>
                          )
                        }
                        {
                          method.link && (
                            <a href={method.link} className="btn btn-primary btn-lg">
                              Proceed
                            </a>
                          )
                        }
                        {
                          method.handler && method.handler(
                            topupOption.amount, 
                            method.id === PaymentMethodId.CODEWALLET ? 
                            topupOption.productId : 
                              method.id === PaymentMethodId.USDCWALLET ? 
                              topupOption.ikonId : undefined
                          )
                        }
                      </>
                    )
                  }
                </div>
              </div>

            </div>
          ))
        }

      </div>
    </>
  );
}