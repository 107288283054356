import { WP_URL } from "./config"
import { TCheckoutPaymentMethod, PaymentMethodId } from "./types"

import CodeWalletIcon from '../assets/img/logos/code-logo.png';
import USDRIcon from '../assets/img/logos/tether-usdt-logo.png';
import MomoIcon from '../assets/img/logos/Mobile-money.jpg';
import CCIcon from '../assets/img/logos/card-payment-mastercard-text-service.png';

import { CodeWalletBtn } from "../components/BuyCredit/CodeWalletBtn";
import { IkonWalletBtn } from "../components/BuyCredit/IkonWallet";

export const defaultPaymentOptions = [
  {
    name: 'Quick Topup',
    amount: 0.5,
    description: '1 video, <300 images',
    ikonId: 'cm732i8tfdq1507jy3x6qs48u',
    productId: 9544
  },
  {
    name: 'Quick Topup',
    amount: 1,
    description: '2 videos, <600 images',
    ikonId: 'cm732i8tfdq1507jy3x6qs48u',
    productId: 9352
  },
  {
    name: 'Quick Topup',
    amount: 2,
    description: '4 videos, <1200 images',
    ikonId: 'cm732i8tfdq1507jy3x6qs48u',
    productId: 9546
  },
]


export const defaultPaymentMethods: (amount, productId, token) => TCheckoutPaymentMethod[] = (amount, productId, token) =>  ([
  {
    id: PaymentMethodId.CODEWALLET,
    name: 'Code Wallet',
    description: 'Pay with KIN using the Code wallet app.',
    subtitle: "Instant topup",
    icon: CodeWalletIcon,
    handler: (amount, productId) => {
      return <CodeWalletBtn productId={ productId } amount = { amount } />
        
      }
  },
  {
    id: PaymentMethodId.USDCWALLET,
    name: 'USDC on Solana',
    subtitle: "Instant topup",
    description: 'Use Phantom or any Solana wallet - powered by Ikonshop.io.',
    icon: USDRIcon,
    isNA: false,
    handler: (amount, ikonId) => {
      // return <></>
      return <IkonWalletBtn ikonId={ ikonId } amount = { amount } />
      }
  },
  {
    id: PaymentMethodId.CREDITCARD,
    name: 'Credit Card',
    description: 'Pay with your credit card. Visa and Mastercard accepted.',
    subtitle: "Instant topup",
    icon: CCIcon,
    link: `${WP_URL}/wp-json/pxl-store-net/v1/validate?j0t=${token}&redirectUrl=${WP_URL}/shop`

  },
  {
    id: PaymentMethodId.MOBILEMONEY,
    name: 'Mobile Money',
    description: 'Pay with any mobile money app. MTN, Vodafone, Airtel, etc.',
    subtitle: "Coming Soon",
    icon: MomoIcon,
    isNA: true
  }
])


