

export const recommendedModels = {
  video: {
    "name": "video-01",
    "run_count": 203262,
    "url": "https://replicate.com/minimax/video-01",
    "description": "Generate 6s videos with prompts or images. (Also known as Hailuo). Use a subject reference to make a video with a character and the S2V-01 model.",
    "cover_image_url": "https://tjzk.replicate.delivery/models_models_featured_image/b56c831c-4c68-4443-b69e-b71b105afe7f/minimax.webp",
    "latest_version": "c8bcc4751328608bb75043b3af7bed4eabcf1a6c0a478d50a4cf57fa04bd5101",
    "example_input": {
      "prompt": "a woman is walking through a busy Tokyo street at night, she is wearing dark sunglasses",
      "prompt_optimizer": true
    },
    "model": "minimax/video-01"
  },
  audio: {
    "name": "mimic-motion",
    "run_count": 2212,
    "url": "https://replicate.com/zsxkib/mimic-motion",
    "description": "MimicMotion: High-quality human motion video generation with pose-guided control",
    "cover_image_url": "https://tjzk.replicate.delivery/models_models_cover_image/2cd4f30b-c19e-49f7-b311-bef4f84de861/cropped_preview_5.gif",
    "latest_version": "b3edd455f68ec4ccf045da8732be7db837cb8832d1a2459ef057ddcd3ff87dea",
    "example_input": {
      "use_fp16": true,
      "chunk_size": 16,
      "resolution": 576,
      "motion_video": "https://replicate.delivery/pbxt/LD5c2cJou7MsS6J7KMBDfywggKAFCfsc2GUAlo67w4Z8aN30/pose1_trimmed_fixed.mp4",
      "sample_stride": 2,
      "frames_overlap": 6,
      "guidance_scale": 2,
      "noise_strength": 0,
      "denoising_steps": 25,
      "appearance_image": "https://replicate.delivery/pbxt/LD5c2GQlXTIlL1i3ZbVcCybtLlmF4XoPoTnbpCmt38MqMQiS/demo1.jpg",
      "output_frames_per_second": 15
    },
    "model": "zsxkib/mimic-motion"
  }
}