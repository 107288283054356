import { nodeAxiosInstance } from "./http/nodeAxiosInstance"


export const updateSessionProps = async (sessionId: string) => {

  const response = await nodeAxiosInstance.post(`/api/sessions/completeDescription`, {sessionId})
  
  return response.data as string
}

export const handleCodePayment = async (payload: {
  intent: string,
  productId: string
}) => {
  const response = await nodeAxiosInstance.post(`/api/topups/verify-purchase`, payload)

  return response.data
}