import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { io } from "socket.io-client";
import { useThreadStore } from "../context/thread.store";
import { updateSessionProps } from "../requests/session-req";
import { getUserCredit } from "../requests/fetch";
import { useSessionStore } from "../context/session.store";

const url = import.meta.env.VITE_APP_WS_URL || "ws://localhost:3351"
const socket = io(url, { autoConnect: false });

function debounce(func, timeout = 700) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    // @ts-ignore
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}


const useSocket = () => {

  const [typingTimer, setTypingTimer] = useState<any>();
  const [sessionId, setSessionId] = useState<string | null>(null);  

  const getMessages = useThreadStore(state => state.getSessionMessages)
  const setTyping = useThreadStore(state => state.setTyping)
  const setIsUploading = useThreadStore(state => state.setIsUploading)
  const addMessage = useThreadStore(state => state.addMessage)

  const authUser = useAuthUser()
  const user = authUser && authUser() as any



  useEffect(() => {

    if(!sessionId) {
      return
    }

    socket.on("connect", () => {
      // console.log("Connected to server successfully");
    });


    socket.on("session", (data) => {
      const { currentSessionId, userId } = data;
      if (!currentSessionId || !userId) {
        return socket.emit("renew", { sessionId, userId: user?.ID })
      }
      sessionStorage.setItem("sessionId", sessionId);

    });


    socket.on("llmResponse", (message) => {
      updateSessionProps(message.agentSessionId)
      addMessage(message)
      // debounce(
      //   getMessages({
      //     sessionId: message.agentSessionId,
      //   })
      // )
      setTyping(false)
    });


    socket.on("typing", () => {
      setTyping(true)
    });

    socket.on("uploading", () => {
      setIsUploading(true)
    });

    socket.on("embeddingComplete", (message) => {
      setIsUploading(false)
    });


    socket.on("stoppedTyping", () => {
      setTyping(false);
    });

    socket.on("payment_confirmed", async (data) => {
      console.log(`Payment confirmed for session ${data.intent} `);
      const { credits, earnings } = await getUserCredit()
      const setUserCredits = useSessionStore.getState().setUserCredits
      const setSelectedOption = useSessionStore.getState().setSelectedPaymentOption

      setSelectedOption(null)
      setUserCredits(credits, earnings);
    });


    return () => {
      console.log("Disconnected from server");
      socket.disconnect();
    }

  }, [getMessages, sessionId, setTyping, user]);

  const onNewFileUpload = (data: {
    documentUrl: string;
    userId: string;
    sessionId: string;
    userEmail: string;
  }) => {
    socket.emit("userDocumentUpload", data);
  }

  const logIn = ({ sessionId, userId }) => {
    setSessionId(sessionId);
    socket.auth = { sessionId, userId };
    socket.connect();
  }


  const logOut = () => {
    // setUser(null);
    sessionStorage.removeItem("sessionId");
    socket.disconnect();
  }


  const sendMessage = (message) => {
    socket.emit("message", message);
  }


  const typing = () => {
    socket.emit("typing");
    clearTimeout(typingTimer);
    setTypingTimer(null);
  }


  const stoppedTyping = () => {
    if (typingTimer == null) {
      setTypingTimer(setTimeout(() => {
        socket.emit("stoppedTyping");
      }, 300));
    }
  }


  const logStates = ({ showUser = false, showUsers = false, showRooms = false, showChat = false }) => {
    if (showUser) console.log(user);
  }


  return {
    socket,
    user,
    logIn,
    logOut,
    sendMessage,
    typing,
    stoppedTyping,
    logStates,
    onNewFileUpload
  };

}

export default useSocket;