
export const CreditPurchaseOptions = ({onSelectOption, topupOptions, selectedOption}) => {

  return (
    <div className={`row gy-3 gy-md-4`}>
      {
        topupOptions.map((option, index) => (
          <div 
            key={index} 
            className={`col-12 col-sm-6 ${selectedOption ? 'd-none' : 'd-block'}`}
            onClick={() => onSelectOption(option)}
          >
            <div className="card widget-card border-light">
              <div className="card-body p-4">
                <div className="row">
                  <div className="col-8">
                    <h5 className="card-title widget-card-title mb-3 fw-bold text-secondary">{option.name}</h5>
                    <h4 className="card-subtitle text-body-secondary m-0"></h4>
                  </div>
                  <div className="col-4">
                    <div className="d-flex justify-content-end">
                      <div className="lh-1 h1 text-black d-flex align-items-center justify-content-center" style={{fontSize: '28px'}}>
                        $ {option.amount}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center mt-3 justify-content-between">
                      <div>
                        <p className="fs-7 mb-0 text-secondary">{option.description}</p>
                      </div>
                      <div>
                        <p className="fs-7 mb-0 text-primary">Choose</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        ))
      }

    </div>

  );
}