import React from 'react'
import { BsChatRightDots } from 'react-icons/bs'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import { useThreadStore } from '../../context/thread.store';

function DocumentActionBtn({ message }) {
  
  const promptDocument = useThreadStore(state => state.promptDocument);
  const setPromptDocument = useThreadStore(state => state.setPromptDocument);

  return (
    <div className="btn-group">
      {
        message.props?.document?.mimeType?.includes('image') && (
          <button className='btn btn-link' onClick={() => {
            if (promptDocument && (promptDocument.key === message?.props?.document.key)) {
              setPromptDocument(null)
            } else {
              setPromptDocument(message?.props?.document)
            }
          }}>
            <BsChatRightDots />&nbsp;
            {promptDocument && (promptDocument.key === message?.props?.document.key) ? 'Cancel Chat' : 'Start Chat'}
          </button>
        )
      }
      <button disabled className='btn btn-link' style={{ color: '#d1d1d1' }}>
        <IoIosCheckmarkCircleOutline />&nbsp;
        {
          message.props?.document?.status?.replace("_", " ").toLowerCase()
        }
      </button>
    </div>)
}

export default DocumentActionBtn