import { useEffect, useState } from "react";
import { CreditPurchaseOptions } from "./Options";
import { CheckoutPayment } from "./Checkout";


import { NODE_ENV, WP_URL } from "../../lib/config";
import { authHeader } from "../../lib/utils";

import { PaymentMethodId, TAppUser, TCheckoutPaymentMethod, TTopupOption } from "../../lib/types";
import { useAppContext } from "../../context/app.context";
import { defaultOptions } from "millify/dist/options";
import useSocket from "../../hooks/useSocket";
import { useAuthUser } from "react-auth-kit";
import { defaultPaymentMethods, defaultPaymentOptions } from "../../lib/payment-data";
import { useSessionStore } from "../../context/session.store";

export const OptionPage = () => {
  const header = authHeader()
  const token = header?.replace('Bearer ', '')
  
  const selectedOption = useSessionStore(state => state.selectedPaymentOption)
  const setSelectedOption = useSessionStore(state => state.setSelectedPaymentOption)
  
  const { userCredits } = useAppContext()

  const devTopupOption = NODE_ENV === 'development' ? [{
    name: 'Quick Topup',
    amount: 0.1,
    description: '1 video, <300 images',
    ikonId: 'cm732i8tfdq1507jy3x6qs48u',
    productId: 39
  }] : []



  const topupOptions = [...devTopupOption, ...defaultPaymentOptions]


  const paymentMethods = defaultPaymentMethods(selectedOption?.amount, selectedOption?.productId, token)


  return (
    <div className="row option-page">
      <div className="col-md-12">
        <div className="main-mail-container gap-4 mb-5 d-lg-flex">

          <div className="total-mails mt-5 mt-lg-5">

            <div className="p-3 d-flex flex-sm-row align-items-center border-bottom sessions-header justify-content-between">

              <div className="text-center text-md-start font-bold">
                <p className="h3">

                  Buy Credits
                </p>
                <p className="h4">
                  Pay with USDC, KIN, Mobile Money or credit card
                </p>
              </div>
              <div className="unstyled d-flex flex-column flex-md-row justify-content-end ms-auto mb-0 ps-md-3 gap-1">
                <div className="d-flex flex-column">
                  <div className="fw-bold">Balance</div>
                  <div className="in">
                    <h1 className="amount m-0">$ {userCredits.credits > 0 ? userCredits.credits.toFixed(2) : '0.00'}</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="mx-auto p-3 p-md-5 w-100">
                <CreditPurchaseOptions
                  selectedOption={selectedOption}
                  topupOptions={topupOptions}
                  onSelectOption={setSelectedOption}
                />

                <CheckoutPayment
                  onRemoveOption={() => setSelectedOption(null)}
                  topupOption={selectedOption}
                  paymentMethods={paymentMethods}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}