// AppContext.js

import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { StPredictionArgs } from "../lib/types";
import {
  createVideoWithSadTalker,
  getPending,
} from "../requests/fetch";
import { getUserAudio, getUserPhotos, requestImageXL } from '../requests/lagacy-api';
import {
  cancelVideoPrediction, getCompleted,
  getImgDescription, getPredictionData,
  getRecordingFromText
} from '../requests/lagacy-api';
import { TUserVoice } from "../lib/types";
import { saveBlobAsDownload } from "../lib/saveBlobAsDownload";
import { sleep } from "../lib/sleep";
import { useLocation } from "react-router-dom";
import { useSessionStore } from "./session.store";

// @ts-ignore

const checkForProgress = async (prediction, setResult) => {
  if (
    prediction &&
    prediction.status !== "succeeded" &&
    prediction.status !== "failed"
  ) {

    let _prediction = await getPredictionData(prediction.id);
    if (!_prediction) {
      setResult(_prediction?.error);
      return;
    }
    
    if (_prediction.status === 'failed') {
      setResult(_prediction?.error);
      return;
    }
    if (_prediction.status !== 'succeeded') {
      setResult(null, _prediction);
      await sleep(2000);
      await checkForProgress(_prediction, setResult)
    } else {
      setResult(null, _prediction);
    }
  }

}

type TNotificationData = {
  message: string;
  title: string;
  type?: "bg-success" | "bg-danger" ;
};

type TAppContext = {
  pendingVideos: any[];
  pendingImages: any[];
  completedVideos: any[];
  completedImages: any[];
  currentPrompt: {
    prompt: string;
    negative_prompt: string;
  };
  userPhotos: {
    url: string;
    name: string;
  }[];
  userCredits: {credits: number, earnings: {kin: number}};
  prediction: any;
  error: any;
  payload: StPredictionArgs;
  userClonedVoices: TUserVoice[];
  selectedVoice: TUserVoice;
  userIsReady: boolean;
  notification: TNotificationData | null;
  isOpenModal: boolean;
  isLoading: boolean;
  handleLoading: (state: boolean) => Promise<void>;
  showNotification: (data: TNotificationData) => Promise<void>;
  cancelPrediction: (predictionId: any) => Promise<void>;
  handleMenu: (state: boolean) => void
  setUserCredits: (credits: number, earnings: {kin: number}) => Promise<void>
};

const AppContext = createContext<TAppContext | undefined>(undefined);

const AppProvider = ({ children }) => {
  // @todo: remove these, clean up this context
  const [prediction, setPrediction] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const [userClonedVoices, setUserClonedVoices] = useState<TUserVoice[]>([]);

  const userCredits = useSessionStore(state => state.userCredits)
  const setUserCredits = useSessionStore(state => state.setUserCredits)
  const [userPhotos, setUserPhotos] = useState<{ url: string; name: string }[]>(
    []
  );
  const [selectedVoice, setSelectedVoiceId] = useState<TUserVoice>({
    voice_name: "Uploaded on " + new Date().toISOString(),
  });

  const [appState, setAppState] = useState({
    notification: null as TNotificationData | null,
    prediction: null,
    error: null,
    isOpenModal: false,
    completed: [],
    pending: [],
    isLoading: false,
    currentPrompt: {
      negative_prompt: '',
      prompt: ''
    } as { prompt: string; negative_prompt: string;} 
  })

  const [isReady, setIsReady] = useState(false)

  const location = useLocation();

  useEffect(() => {
    setAppState(prev => ({...prev, isOpenModal: false}))
  }, [location])


  const [payload, setPayload] = useState<StPredictionArgs>({
    driven_audio: null,
    ref_eyeblink: null,
    ref_pose: null,
    source_image: null,
  });

  const userIsReady = useMemo(() => {
    return isReady && userCredits.credits > 0
  }, [isReady, userCredits])


  const cancelPrediction = useCallback(async (predictionId) => {
    await cancelVideoPrediction(predictionId)
    alert("cancelled ")
  }, [])


  const showNotification = useCallback(async (notification: TNotificationData) => {
    // Use the functional form of setAppState to ensure the latest state
    setAppState(prevState => ({
      ...prevState,
      notification
    }));
  
    // Use setTimeout with a callback function to handle state updates
    setTimeout(() => {
      setAppState(prevState => ({
        ...prevState,
        notification: null
      }));
    }, 3000); // Specify a minimal delay to ensure it's executed in the next tick
  }, [setAppState]); 

  const handleMenu = useCallback((isOpenModal: boolean) => {
    setAppState((prev) => ({...prev, isOpenModal}))
  },[])

  const handleLoading = useCallback( async (isLoading: boolean) => {
    setAppState((prev) => ({...prev, isLoading}))
  },[])

  const setCredits = useCallback(async (credits: number, earnings: {kin: number}) => {
    setUserCredits(credits, earnings)
  }, [])

  const value = useMemo<TAppContext>(
    () => ({
      pendingVideos: appState.pending,
      completedImages: appState.completed,
      completedVideos: appState.completed,
      pendingImages: appState.pending,
      notification: appState.notification,
      isOpenModal: appState.isOpenModal,
      isLoading: appState.isLoading,
      currentPrompt: appState.currentPrompt,
      userPhotos,
      userCredits,
      error,
      prediction,
      payload,
      userClonedVoices,
      selectedVoice,
      userIsReady,
      setUserCredits: setCredits,
      cancelPrediction,
      showNotification,
      handleMenu,
      handleLoading,
    }),
    [

      cancelPrediction,
      setCredits,
      selectedVoice,
      userCredits,
      userPhotos,
      error,
      prediction,
      payload,
      userClonedVoices,
      userIsReady,
      showNotification,
      handleMenu,
      handleLoading,
      appState.notification,
      appState.isOpenModal,
      appState.completed,
      appState.pending,
      appState.isLoading,
      appState.currentPrompt,
    ]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};

export default AppProvider;
