/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import { useCallback, useRef, useState } from 'react'

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { FaRegCopy, FaReply } from 'react-icons/fa6'
import { GoReport } from 'react-icons/go'
import moment from 'moment'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomDark } from 'react-syntax-highlighter/src/styles/prism'
import { useAuthUser } from 'react-auth-kit'

import { TMessage, TMessageEnum } from "../../lib/types"
import { checkMessageSender, findAgentFromContent } from '../../lib/utils'
import { useThreadStore } from '../../context/thread.store'
import agentAvi from './../../assets/img/software-agent.png'
import codeLogo from './../../assets/img/code-logo.webp'
import userAvi from './../../assets/img/user.png'
import ReportMessage from '../ReportMessage'
import { fetchCodeIntent } from '../../requests/message-req'
import { useSessionStore } from '../../context/session.store';
import ModalBackdrop from '../ModalBackdrop';
import { FaCheckSquare } from 'react-icons/fa';
import { CustomLink } from './CustomLink'
import DocumentActionBtn from './DocumentActionBtn'

type TMessageProps = {
  message: TMessage
}

function toCalendar(date: string) {
  return moment(date).calendar()
}

function CopyMessageBtn({ messageContent }) {
  const [copyOk, setCopyOk] = useState(false);

  const handleClick = async (e) => {

    await navigator.clipboard.writeText(messageContent);

    setCopyOk(true);
    setTimeout(() => {
      setCopyOk(false);
    }, 500);
  }

  return (
    <button onClick={handleClick} type="button" className={`btn ${!copyOk ? 'btn-link' : 'btn-success'} btn-sm`}>
      {
        copyOk ?
          <FaCheckSquare /> :
          <FaRegCopy />
      }
      &nbsp;
      <span className="sr-only d-none d-md-block">
        Copy
      </span>
    </button>
  )
}

function CopyCodeBtn({ children }) {
  const [copyOk, setCopyOk] = useState(false);

  const handleClick = async (e) => {

    await navigator.clipboard.writeText(children.props.children);

    setCopyOk(true);
    setTimeout(() => {
      setCopyOk(false);
    }, 500);
  }

  return (
    <button onClick={handleClick} type="button" className={`btn ${!copyOk ? 'btn-primary' : 'btn-success'} btn-sm`}>
      {
        copyOk ?
          <FaCheckSquare /> :
          <FaRegCopy />
      }
      &nbsp;Copy
    </button>
  )
}

function Message({ message }: TMessageProps) {
  const runTask = useThreadStore(state => state.runTask)
  const sessionAgents = useThreadStore(state => state.sessionAgents)
  const setComposing = useThreadStore(state => state.setComposing)
  const reportMessage = useThreadStore(state => state.reportMessage);

  const sessionId = useSessionStore(state => state.sessionId)

  const authUser = useAuthUser()
  const user = authUser()

  const messageContentElement = useRef<HTMLDivElement | null>(null)


  const [messageState, setState] = useState({
    btnMounted: false,
    reportModal: {
      show: false,
      msgId: null as string | null
    },
  })

  const { reportModal } = messageState
  const setReportModal = (reportModal) => {
    setState(state => ({ ...state, reportModal }))
  }

  const handleBasicRes = async (message: TMessage) => {
    //
    const agent = findAgentFromContent(message.content, sessionAgents)
    await runTask(message, sessionId!, agent!, TMessageEnum.BASIC_TASK_SPEC)
  }


  const loadCodeBtn = useCallback((message: TMessage, amount = 0.10) => {
    const code = {} as any
    const { button } = code.elements.create('button', {
      currency: 'usd',
      destination: 'DagRzeaZGrvk7euSpnmZ8TmWYNc3kJzUes91WnU1t9e',
      amount: amount || 1,
    });

    if (!button) return

    button.on('success', async () => {
      const agent = findAgentFromContent(message.content, sessionAgents)
      await runTask(message, sessionId!, agent!, TMessageEnum.TURBO_TASK_SPEC)
      return true;
    });

    button.on('cancel', async () => {
      setState(state => ({ ...state, btnMounted: false }))
      return true;
    });

    button.on('invoke', async () => {

      // Get a payment intent from our own server
      const res = await fetchCodeIntent({
        amount,
        messageId: message.id
      });
      const { clientSecret } = res;

      // Update the button with the new client secret so that our server
      // can be notified once the payment is complete.
      button.update({ clientSecret });
    })

    button.mount(`#code-payment-btn-${message.id}`);
    setState(state => ({ ...state, btnMounted: true }))

  }, []);

  const handleReply = (message: TMessage) => {
    //
    setComposing(message.id)
  }

  const isAgentMessage = (message: TMessage) => {
    return [
      TMessageEnum.AGENT_MESSAGE,
      TMessageEnum.BASIC_TASK_REPLY,
      TMessageEnum.TURBO_TASK_REPLY,
    ].includes(message.props?.messageType)
  }


  return (
    <>
      <div className="content message-block" style={{ padding: 24 }}>

        <div className="d-xl-flex d-block align-items-center justify-content-start mb-1">
          <div className="float-xl-end" >
            <span className="me-2 fs-12 text-muted">
              {toCalendar(message.createdAt)}
            </span>
          </div>
          {
            message.props?.messageType && message.props?.messageType == TMessageEnum.TURBO_TASK_REPLY && (
              <div className="float-xl-end" >
                <span className="me-2 fs-12 badge badge-dark">
                  ⚡️&nbsp;Tipped
                </span>
              </div>
            )
          }
        </div>
        <div className="d-flex align-items-center mb-3">

          <div className="me-1">

            <span className="avatar avatar-md online me-2 avatar-rounded mail-msg-avatar">

              <img src={checkMessageSender(message, user) ? userAvi : agentAvi} alt={message.sender} />
            </span>
          </div>
          <div className="flex-fill">

            <h6 className="mb-0 fw-semibold">
              {checkMessageSender(message, user) ? 'You' : 'Agent'}
            </h6>
            <span className="text-muted fs-12 mesg-sndr">
              {message.sender}
            </span>
          </div>
        </div>
        <div className="main-mail-content my-4" >
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={{
              a: ({ href, children, ...props }: { [x: string]: any; href?: string; children?: any; }) => <CustomLink href={href} {...props}>{children}</CustomLink>,

              pre: ({ children }) => (
                <>
                  <CopyCodeBtn>{children}</CopyCodeBtn>
                  {children}
                </>
              ),
              code(props) {
                const { children, className, node, ...rest } = props
                const match = /language-(\w+)/.exec(className || '')
                return match ? (
                  <SyntaxHighlighter
                    {...rest}
                    PreTag="div"
                    children={String(children).replace(/\n$/, '')}
                    language={match[1]}
                    style={atomDark}
                  />
                ) : (
                  <code {...rest} className={className}>
                    {children}
                  </code>
                )
              }
            }}
          >
            {message.content}
          </Markdown>
        </div>
        <div className="mail-attachments mb-4">
          <div className="d-sm-flex justify-content-between align-items-center">
            {
              message.props?.document && (
                <DocumentActionBtn 
                  message={message} 
                />
              )
            }
            {
              message.props &&
              message.props.messageType &&
              message.props.messageType == TMessageEnum.TASK_SPEC &&
              (
                <div className="d-flex flex-column gap-2 btn-toolbar">
                  <div className='d-flex gap-2'>
                    <button className='btn btn-outline-primary' onClick={() => handleBasicRes(message)} >No Tips</button>
                    <button className='btn btn-dark' onClick={() => loadCodeBtn(message)} >
                      <img src={codeLogo} alt="code logo" style={{ width: 20, height: 20 }} />
                      &nbsp;
                      Tip Agent
                    </button>
                  </div>
                  <div className="d-flex justift-content-start btn-toolbar">
                    <div id={`code-payment-btn-${message.id}`} ></div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
        <div className="mb-4 d-flex justify-content-between">
          <div>
            {
              isAgentMessage(message) && (
                <span title='How can this be better?' className="flag-action" onClick={() => setReportModal({ show: true, msgId: message.id })}>
                  <GoReport />&nbsp;
                </span>
              )
            }
          </div>
          <div className='d-flex justify-content-between'>
            <div className="btn-group">
              <CopyMessageBtn messageContent={message.content} />
            </div>
            {
              isAgentMessage(message) && (
                <div className="btn-group d-none">
                  <button className='btn btn-outline-dark' onClick={() => handleReply(message)} >
                    <FaReply />&nbsp;
                    Forward
                  </button>
                </div>
              )
            }
          </div>
        </div>
      </div>
      {
        reportModal.show && reportModal.msgId === message.id && (
          <>
            <ReportMessage
              close={() => {
                setReportModal({ show: false, msgId: null });
              }}
              reportMessage={async (reason) => {
                await reportMessage(reason, message.id!);
                setReportModal({ show: false, msgId: null });
              }}
              message={message.content}
            />
            <ModalBackdrop handleClick={() => (setReportModal({ show: false, msgId: null }), console.log('cal'))} />
          </>
        )
      }
    </>
  )
}

export default Message