import { useState, lazy, Suspense, useCallback, useMemo } from 'react'
import { FaSearch } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { fetchMatchingModels } from '../requests/agents-req';
import { useNavigate } from 'react-router-dom';
import { useAgentStore } from '../context/agent.store';
import { useAppContext } from '../context/app.context';
import { AgentTools, AGENT_TYPE, TReplicateModel } from '../lib/types';
import { MdOutlineCircle } from 'react-icons/md';
import ModelItem from '../components/agent/replicate/ModelItem';
import { recommendedModels } from '../lib/replicate_models';

const ModelList = lazy(() => import('../components/agent/replicate/ModelList'));

function CreateReplicateAgent() {

  const { showNotification } = useAppContext()

  const navigate = useNavigate()

  const addAgent = useAgentStore(state => state.saveCustomAgent)
  const fetchCustomAgents = useAgentStore(state => state.fetchCustomAgents)
  const onEnterUrls = useAgentStore(state => state.addToolData)
  const isLoading = useAgentStore(state => state.isLoading)

  const [form, setForm] = useState<{
    task: string;
    isLoading: boolean;
    selectedModel: TReplicateModel | null;
  }>({
    task: "",
    isLoading: false,
    selectedModel: null
  })
  const [models, setModels] = useState<any[]>([])
  const [sortBy, setSortBy] = useState('run_count')

  const getMatchingModels = async (description) => {
    setForm(form => ({...form, isLoading: true}))
    try {
      const requestModels = await fetchMatchingModels(description)
      const sortedModels = [...requestModels].sort((a, b) => b.run_count - a.run_count);
      
      setModels(sortedModels)
      setForm(form => ({...form, isLoading: false}))
      
    } catch (e) {
      setForm(form => ({...form, isLoading: false}))
      showNotification({
        title: 'Search Failed',
        message: 'This is not your fault. We will look into it.',
        type: 'bg-danger'
      });
    }
  }

  const handleSelectModel = (selectedModel) => {
    setForm(form => ({ ...form, selectedModel }))
  }

  const handleRemoveModel = () => {
    setForm(form => ({ ...form, selectedModel: null }))
  }

  const saveAgent = useCallback(async () => {
    if (!form.selectedModel) return
    onEnterUrls({
      data: form.selectedModel,
      name: AgentTools.REPLICATETOOL
    })
    //
    await addAgent({
      agentType: AGENT_TYPE.CUSTOM_AGENT,
      backstory: form.selectedModel.description!,
      goal: form.task,
      name: form.selectedModel.name!,
      taskDescription: `${form.task}`
    })

    showNotification({
      title: 'Agent Created',
      message: form.selectedModel.name + ' Added',
      type: 'bg-success'
    });

    navigate('/chat')

    await fetchCustomAgents()
  }, [form])

  const displayModels = useMemo(() => {
    if (sortBy === 'run_count') {
      return [...models].sort((a, b) => b.run_count - a.run_count);
    }
    return models
  }, [models, sortBy])


  return (
    <div className="row create-replicate-agent">
      <div className="col-md-12">
        <div className="main-mail-container gap-4 mb-5 d-lg-flex">
          <div className="total-mails mt-5 mt-lg-5">
            <div className="p-3 d-flex align-items-center border-bottom">
              <div className="me-3">
                <h3>
                  Add an AI Agent with a Model &nbsp;
                  <span className="badge badge-pill badge-info">Beta</span>

                </h3>
              </div>
            </div>
            <div className="section mt-2 text-left">
              <div className='py-3 my-3 mb-4'>
                <div className="row">
                  <div className="col-md-8">
                    <h1>Find a Model</h1>
                    <h3>Search for a model suitable for your task.</h3>
                    <h4>
                      Replicate.com hosts AI models for text, image, video and audio generation. Create an AI Agent that handles
                      your Replicate.com tasks.
                    </h4>

                    <h3 className='my-3'>Recommendations</h3>
                    <ul className='list-group list-group-flush'>
                      
                      <li onClick={() => handleSelectModel(recommendedModels.video)} className='list-group-item list-group-item-action'>
                        <div className="d-flex flex-column w-100 justify-content-between">
                          <h5 className="mb-1 fw-bold">
                            
                            {recommendedModels.video.name}
                          </h5>
                          <small>
                            {recommendedModels.video.description}
                          </small>
                        </div>
                      </li>
                      <li onClick={() => handleSelectModel(recommendedModels.audio)} className='list-group-item list-group-item-action'>
                        <div className="d-flex flex-column w-100 justify-content-between">
                          <h5 className="mb-1 fw-bold">
                            
                            {recommendedModels.audio.name}
                          </h5>
                          <small>
                            {recommendedModels.audio.description}
                          </small>
                        </div>
                      </li>

                    </ul>
                    <div className="card mt-5">
                      <div className="card-body">
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label">
                              What do you want to do?
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="eg. Generate videos"
                              value={form.task}
                              onChange={e => setForm({ ...form, task: e.target.value })}
                            />
                          </div>
                        </div>
                        <button disabled={form.isLoading} className="btn btn-outline-info" onClick={() => getMatchingModels(form.task)}>
                          <span className="btn-white">
                            <FaSearch color='blue' />
                          </span>
                          &nbsp;
                          Search
                          &nbsp;
                          {
                            form.isLoading && (

                              <span className='animate__animated animate__heartBeat animate__infinite'>
                                <MdOutlineCircle />
                              </span>
                            )
                          }
                        </button>
                      </div>
                    </div>
                    {
                      form.selectedModel && (
                        <div className="card mt-5">
                          <div className="card-body">
                            <ul className="list-style-none">
                              <ModelItem model={form.selectedModel} onSelectModel={handleRemoveModel} />
                            </ul>

                          </div>
                        </div>
                      )
                    }
                    {
                      !form.selectedModel && displayModels.length > 0 ? (
                        <Suspense fallback={<Skeleton count={10} />}>
                          <ModelList 
                            onChangeSort={setSortBy} 
                            sortby={sortBy} 
                            models={displayModels} 
                            onSelectModel={handleSelectModel} 
                          />
                        </Suspense>
                      ) : (
                        <div className="card mt-3">
                          <div className="card-body">
                            {
                              form.selectedModel ? (
                                <h3 className='text-center'>
                                  Click to change selected model
                                </h3>
                              ) : (
                                <h3 className='text-center'>
                                  No Models found. Please try searching by typing your task.
                                </h3>
                              )
                            }
                          </div>
                        </div>
                      )
                    }
                  </div>
                  <div className="form-button mt-5  transparent">
                    <button disabled={isLoading || !form.selectedModel} onClick={() => saveAgent()} className="btn btn-primary ">
                      Create Agent&nbsp;
                      {
                        isLoading && (

                          <span className='animate__animated animate__heartBeat animate__infinite'>
                            <MdOutlineCircle />
                          </span>
                        )
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateReplicateAgent